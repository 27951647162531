/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Eyebrow} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    hr: "hr",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, "Usage", React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Wrap content using the BreakPoint component and define max-width or min-width for the content to be revealed. When hidden, it also removes itself from DOM."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className='m-t-2'>\n\t<Eyebrow>Current width: </Eyebrow>\n\t<BreakPoint max='tablet'>mobile</BreakPoint>\n\t<BreakPoint min='tablet' max='desktop'>tablet</BreakPoint>\n\t<BreakPoint min='desktop' max='large'>large</BreakPoint>\n\t<BreakPoint min='large'>widescreen</BreakPoint>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "max-width",
    style: {
      position: "relative"
    }
  }, "Max width", React.createElement(_components.a, {
    href: "#max-width",
    "aria-label": "max width permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "title: Resize the browser to see the change\nnoResize: true\n---\n<Stack spacing={2}>\n\t<BreakPoint max='mobile'>\n\t\t<Card className='fs-2'>smaller than mobile</Card>\n\t</BreakPoint>\n\t<BreakPoint max='desktop'>\n\t\t<Card className='fs-2'>smaller than tablet</Card>\n\t</BreakPoint>\n\t<BreakPoint max='large'>\n\t\t<Card className='fs-2'>smaller than large</Card>\n\t</BreakPoint>\n\t<BreakPoint max='widescreen'>\n\t\t<Card className='fs-2'>smaller than widescreen</Card>\n\t</BreakPoint>\n\n\t{/* component to show when nothing meets `max` prop */}\n\t<BreakPoint min='widescreen'><Eyebrow>nothing to display</Eyebrow></BreakPoint>\n</Stack>\n")), "\n", React.createElement(_components.h2, {
    id: "min-width",
    style: {
      position: "relative"
    }
  }, "Min width", React.createElement(_components.a, {
    href: "#min-width",
    "aria-label": "min width permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "title: Resize the browser to see the change\nnoResize: true\n---\n<Stack spacing={2}>\n\t<BreakPoint min='mobile'>\n\t\t<Card className='fs-2'>larger than mobile</Card>\n\t</BreakPoint>\n\t<BreakPoint min='desktop'>\n\t\t<Card className='fs-2'>larger than tablet</Card>\n\t</BreakPoint>\n\t<BreakPoint min='large'>\n\t\t<Card className='fs-2'>larger than large</Card>\n\t</BreakPoint>\n\t<BreakPoint min='widescreen'>\n\t\t<Card className='fs-2'>larger than widescreen</Card>\n\t</BreakPoint>\n\n\t{/* component to show when nothing meets `min` prop */}\n\t<BreakPoint max='mobile'><Eyebrow>nothing to display</Eyebrow></BreakPoint>\n</Stack>\n")), "\n", React.createElement(_components.h2, {
    id: "max-and-min-width",
    style: {
      position: "relative"
    }
  }, "Max and Min width", React.createElement(_components.a, {
    href: "#max-and-min-width",
    "aria-label": "max and min width permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "title: Resize the browser to see the change\nnoResize: true\n---\n<Stack spacing={2}>\n\t<BreakPoint min='mobile' max='tablet'>\n\t\t<Card className='fs-2'>between mobile and tablet</Card>\n\t</BreakPoint>\n\t<BreakPoint min='tablet' max='desktop'>\n\t\t<Card className='fs-2'>between tablet and desktop</Card>\n\t</BreakPoint>\n\t<BreakPoint min='desktop' max='large'>\n\t\t<Card className='fs-2'>between desktop and large</Card>\n\t</BreakPoint>\n\t<BreakPoint min='large' max='widescreen'>\n\t\t<Card className='fs-2'>between large and widescreen</Card>\n\t</BreakPoint>\n\n\t{/* component to show when nothing meets `min` or `max` prop */}\n\t<BreakPoint max='mobile'><Eyebrow>nothing to display</Eyebrow></BreakPoint>\n\t<BreakPoint min='widescreen'><Eyebrow>nothing to display</Eyebrow></BreakPoint>\n</Stack>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When hiding the content, create an alternative method to reveal the content."), "\n", React.createElement(_components.li, null, "The BreakPoint should be used to improve the responsive layout of a screen not to just fit things on a screen."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
